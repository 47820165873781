.filter-panel {
  &__grid {
    position: absolute;
    top: 40px;
    left: 0;
    width: 800px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    background-color: white;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
      rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  }

  &__list {
    list-style: none;

    &-item {
      display: flex;
      align-items: center;
    }

    &-header {
      padding-left: 40px;
    }
  }

  &__header {
    padding-left: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    align-items: center;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;
    font-size: 18px;
    background-color: transparent;
    border: none;
    outline: none;
  }
}

body.dark-theme {
  & .filter-panel {
    &__grid {
      background-color: #121212;
      color: white;
      border: 1px solid white;
    }
  }
}
