@import '../../../utils/mixins';

.header {
  font-family: 'Rubik';
  background-color: #070a25;
  overflow: hidden;
  height: 100vh;
  position: relative;
  // filter: blur(5px);

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 390px;
    height: 420px;
    right: 0;
    top: 0;
    background-image: url(../../../assets/images/Untitled-2.svg);
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    border: 1px solid #000000;
    transform: translateX(65%);
    z-index: 0;

    @include onDesktop {
      display: none;
    }
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 440px;
    height: 420px;
    left: 0;
    bottom: 0;
    background-image: url(../../../assets/images/Untitled-1.svg);
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    border: 1px solid #000000;
    z-index: 0;

    @include onDesktop {
      display: none;
    }
  }

  &__container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 30px;

    @include onDesktop {
      align-items: center;
      padding: 50px 60px 200px 60px;
    }
  }

  &__container::after {
    display: none;
    position: absolute;
    content: '';
    width: 980px;
    height: 890px;
    right: 0;
    top: 0;
    background-image: url(../../../assets/images/Untitled-2-desk.svg);
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    border: 1px solid #000000;
    transform: translateX(65%);
    z-index: 0;

    @include onDesktop {
      display: block;
    }
  }

  &__container::before {
    position: absolute;
    display: none;
    content: '';
    width: 440px;
    height: 530px;
    left: 0;
    bottom: 0;
    background-image: url(../../../assets/images/Untitled-1-desk.svg);
    background-repeat: no-repeat;
    mix-blend-mode: screen;
    border: 1px solid #000000;
    z-index: 0;

    @include onDesktop {
      display: block;
    }
  }

  &__menu-desktop {
    display: none;

    @include onDesktop {
      display: flex;
      z-index: 100;
    }
  }

  &__block-menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 190px;

    @include onDesktop {
      margin-bottom: 150px;
    }
  }

  &__logo {
    width: 200px;
    height: 30px;
  }

  &__logo-img {
    width: 100%;
  }

  &__button-menu--open {
    background: transparent;
    border: none;
    background-image: url(../../../assets/images/Menu-open.svg);
    background-size: cover;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 100;
    @include onDesktop {
      display: none;
    }
  }
  &__button-menu--close {
    background: transparent;
    border: none;
    background-image: url(../../../assets/images/Menu-clouse.svg);
    background-size: cover;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 100;
  }

  &__slogan {
    margin-bottom: 70px;
    font-weight: 700;
    font-size: 36px;
    line-height: 125%;
    letter-spacing: 0.007em;
    color: #fff;
    text-align: left;

    @include onDesktop {
      max-width: 900px;
      font-size: 64px;
      text-align: center;
    }
  }

  &__slogan-start {
    color: #0ad5ff;
  }

  &__partnership-button {
    display: flex;
    justify-content: center;
    padding: 15px 20px 15px 20px;
    border: 1px solid #0463ef;
    border-radius: 40px;
    text-decoration: none;

    @include onDesktop {
      max-width: 396px;
      padding: 15px 40px 15px 40px;
    }
  }

  &__partnership-button:hover {
    background-color: #0463ef;
    box-shadow: 0px 0px 10px #0463ef;
  }

  &__partnership-link {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-decoration: none;
    margin-right: 20px;

    @include onDesktop {
      font-size: 20px;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
  }

  &__arrow-line {
    width: 36px;
    height: 1px;
    background-color: #fff;

    @include onDesktop {
      width: 62px;
    }
  }
  &__arrow-line-bottom {
    width: 5px;
    height: 5px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);
    margin-left: -5px;
  }

  &__menu-phone--visible {
    box-sizing: border-box;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100vh;
    height: 100vh;
    transform: translateX(0);
    transition: all 1s ease-out;
  }

  &__menu-phone--hidden {
    box-sizing: border-box;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100vh;
    height: 100vh;
    transform: translateX(-100%);
    transition: all 1s ease-out;
  }
}
