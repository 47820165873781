@import '../../../utils/mixins';

.footer {
  font-family: 'Rubik', sans-serif;

  &__partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 22px 50px 22px;
    background-color: #070a25;

    @include onDesktop {
      padding: 120px 22px 100px 22px;
    }
  }
  &__partners-title {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;

    @include onDesktop {
      font-size: 50px;
      margin-bottom: 90px;
    }
  }
  &__partners-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    list-style: none;
    padding: 0;
    gap: 20px;

    @include onDesktop {
      display: grid;
      width: 960px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 60px;
      grid-row-gap: 60px;
    }
  }

  &__partners-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(4, 99, 241, 0.1);
    min-width: 155px;
    height: 75px;

    @include onDesktop {
      max-width: 290px;
      height: 170px;
    }
  }

  &__partners-item:hover {
    background-color: #fff;
  }

  &__partners-text {
    margin-top: 40px;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    letter-spacing: 0.02em;

    @include onDesktop {
      font-size: 24px;
      letter-spacing: 0.04em;
    }
  }

  &__partners-image {
    max-width: 100px;
    max-height: 50px;
  
    @include onDesktop {
      max-width: 180px;
      max-height: 90px;
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px 35px;
    background-color: #0463ef;

    @include onDesktop {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 30px 60px;
    }
  }
  &__logo {
    width: 200px;
    height: 30px;
    margin-bottom: 70px;

    @include onDesktop {
      margin-bottom: 0;
    }
  }

  &__logo-img {
    width: auto;
  }

  &__nav {
    padding: 0;
    list-style: none;
    text-align: left;

    @include onDesktop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 50px;
    }
  }
  &__nav-item {
    margin-bottom: 30px;

    @include onDesktop {
      margin-bottom: 0;
    }
  }

  &__nav-link {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }

  &__nav-link:hover {
    color: #021e47;
  }

  &__nav-link--fone {
    position: relative;
    padding-left: 45px;
  }

  &__nav-link--fone::before {
    position: absolute;
    display: block;
    content: '';
    width: 36px;
    height: 20px;
    left: 0;
    top: 2px;
    background-image: url(../../../assets/images/email.svg);
    background-repeat: no-repeat;
  }

  &__copyright {
    position: relative;
    width: 100%;
    text-align: left;
    padding-top: 35px;

    @include onDesktop {
      padding-top: 0;
      width: auto;
    }
  }

  &__copyright::before {
    position: absolute;
    left: -35px;
    top: 0;
    content: '';
    width: 120%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.2);
    background-repeat: no-repeat;

    @include onDesktop {
      display: none;
    }
  }
  &__copyright-item {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;

    color: #ffffff;
  }
}
