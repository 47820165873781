/* @font-face {
  font-family: 'Futura';
  src: url(./assets/fonts/Futura.ttf);
} */

@font-face {
  font-family: 'Rubik Regular';
  src: url('./assets/fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik Medium';
  src: url('./assets/fonts/Rubik-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ruda';
  src: url('./assets/fonts/Ruda-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway Light';
  src: url('./assets/fonts/Raleway-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root .css-ur2jdm-MuiContainer-root,
#root .css-100567u-MuiContainer-root,
#root .css-10ur324 #root .MuiContainer-root {
  padding: 0;
  margin: 0;
  width: 100%;
}

@media (min-width: 600px) {
  #root .css-100567u-MuiContainer-root {
    padding-left: 0;
    padding-right: 0;
  }
}

.error {
  border: none !important;
  border: 1px solid red !important;
  border-radius: 5px;
}

body {
  margin: 0;
  width: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont,  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark-theme {
  background-color: #121212;
}

.ofer-logo {
  object-fit: cover;
}

body.dark-theme
  input:-webkit-autofill:not(.login__item):not(.registration__item),
body.dark-theme
  input:-webkit-autofill:hover:not(.login__item):not(.registration__item),
body.dark-theme
  input:-webkit-autofill:focus:not(.login__item):not(.registration__item),
body.dark-theme
  input:-webkit-autofill:active:not(.login__item):not(.registration__item) {
  -webkit-box-shadow: 0 0 0 30px #121212 !important;
  box-shadow: 0 0 0 30px #121212 inset !important;
  background-color: transparent;
  background: transparent;
}

@media (min-width: 600px) {
  body .MuiDataGrid-panel
  { 
  inset: 0px auto auto 200px !important;
  width: 100px ;
}
}

