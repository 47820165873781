@import '../utils/mixins';


#root .css-ur2jdm-MuiContainer-root,
#root .css-100567u-MuiContainer-root,
#root .css-10ur324
#root .MuiContainer-root
 {
    padding: 0;
    margin: 0;
    width: 100%;
  }