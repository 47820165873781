@import '../../../../utils/mixins';

.registration {
  color: #070A25;
  display: flex;
  font-family: 'Rubik';
 
  
  &__header {
    display: none;
    margin: 0;
    box-sizing: border-box;

    @include onDesktop {
      display: block;
      width: 100vw;
      height: 100vh;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 35px;
    padding-bottom: 45px;
    background-color: #ffffff;
    z-index: 101;
    margin: 0;    

    @include onDesktop {
      position: fixed;
      align-items: center;
      justify-content: center;
      width: 450px;
      padding: 30px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);    
      border-radius: 45px;
    }
  }

  &__block-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    @include onDesktop {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  &__logo {
    width: 200px;
    height: 30px;

    @include onDesktop {
      display: none;
    }
  }

  &__logo-img {
    width: 100%;
  }

  &__button-close {
    background: transparent;
    border: none;
    background-image: url(../../../../assets/images/Menu-clouse-registration.svg);
    background-size: cover;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 100;
 
  }

  &__title {
    display: block;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    margin-bottom: 30px;

    @include onDesktop {
      margin-bottom: 35px;
    }
  }

  &__button-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;

    @include onDesktop {
      gap: 25px;
    }
  }

  &__button-item {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__button-label {
    margin: 0;
    padding: 10px 10px 10px 10px;
    margin-bottom: 10px;
    display: flex;
    background: rgba(10, 213, 255, 0.2);
    border-radius: 9px;
    font-weight: 400;
    font-size: 18px;
    color: #070a25;
    cursor: pointer;
  }
  
  &__button-item:checked + &__button-label {
    background: #0ad5ff;
    font-weight: 600;
    font-size: 17px;

  }

  &__personal-information {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding-top: 30px;
    width: 100%;
    margin-bottom: 35px;   
    
    @include onDesktop {
      padding-top: 15px;     
    }
  }

  &__item {
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 5px;
    color: #000;
    border-bottom: 1px solid #b8b8b8;

  }

  &__item-error {
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 5px;
    color: #000;
    border-bottom: 1px solid #fa0606;
  }

  &__item::placeholder {
    font-weight: 400;
    color: #8b8a8a;
  }

  &__agreement {
    display: flex;
    max-width: 230px;
    gap: 35px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    @include onDesktop {
      max-width: 100%;
    }
  }

  &__agreement-input {
    width: 20px;
      height: 20px;
    border: 2px solid #a6a6a6;
    border-radius: 6px;

  }

  &__agreement-label {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #070a25;

    @include onDesktop {
      display: flex;
    }
  }

  &__agreement-link {
    text-align: left;
    padding-left: 5px;
    font-weight: 700;
    color: #070a25;
     border-bottom: 1px solid #070a25;
    cursor: pointer;
  }

  &__button {
    width: 100%;
    padding: 13px 35px;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    background: #0463ef;
    border: none;
    border-radius: 45px;
    margin-bottom: 25px;
    cursor: pointer;

    @include onDesktop {     
      padding: 17px 35px;
    }
  }
  &__button:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #4a8bee;
  }
  

  &__come-in {
    display: flex;
    justify-content: center;
    gap:15px;
  }

  &__come-in-text {
    font-weight: 400;
    font-size: 14px;
    color: #070a25;
    
  }

  &__come-in-link {
    font-weight: 700;
    font-size: 14px;
    text-decoration-line: underline;
    color: #0463ef;
  }

  
  
  &__messege {
    color: red;
    font-weight: 400;
    font-size: 16px;   
    text-align: center; 
    margin-bottom: 15px;
  }
}
