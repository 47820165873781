.panel-header {
  display: none;
}

.hidden {
  opacity: 0;
}

.custom-header-cell {
  & .MuiDataGrid-columnHeaderTitle {
    white-space: normal;
    line-height: 1.2;
    max-height: 35px;
  }
}
