@import '../../../utils/mixins';

.menu {
  font-family: 'Rubik';
  background-color: #070a25;
  padding: 0;  
  z-index: 101;

  &__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 75px 30px;

    @include onDesktop {
      max-height: 60px;
    }

    @include onDesktop {
      flex-direction: row-reverse;
      align-items: center;
      justify-content:space-between;
      
      padding: 0;
    }
  }

  &__for-user {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    margin-bottom: 65px;

    @include onDesktop {
      flex-direction: row;
      margin-bottom: 0;
      margin-left: 40px;
    }
  }

  &__for-user-link {
    display: block;
    padding: 15px 40px;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-decoration: none;
    background: transparent;
    border: 1px solid #0463ef;
    border-radius: 40px;

    @include onDesktop {
      
      font-size: 20px;
      padding: 10px 20px;
    }
  }

  &__for-user-link-cabinet {
    width: 170px;
    margin-bottom: 60px;

    @include onDesktop {
      width: 120px;
      margin-bottom: 0;
      margin-left: 40px;
    }
  }
  &__for-user-link:hover {
    background: #0463ef;
    box-shadow: 0px 0px 10px #0463ef;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    position: relative;
    width: 100%;
    padding: 0;
    padding-top: 65px;
    list-style: none;

    @include onDesktop {
      flex-direction: row;
      padding-top: 0;
      gap: 50px;
    }
  }

  &__nav::before {
    position: absolute;
    left: -70px;
    top: 0;
    content: '';
    width: 110%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.2);
    background-repeat: no-repeat;

    @include onDesktop {
      display: none;
    }
  }

  &__nav-link {
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 0.007em;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;

    @include onDesktop {
      font-size: 20px;
    }
  }

  &__nav-link:hover {
    color: #0463ef;
  }
}
