.filter-panel-column {
  // &__grid {
   
  //   column-gap: 20px;
  //   background-color: white;
  //   z-index: 999;
  //   box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
  //     rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  // }

  &__list {
    list-style: none;

    &-item {
      display: flex;
      align-items: center;
    }

    // &-header {
    //   padding-left: 40px;
    // }
  }

 
}

 
body.dark-theme {
  & .filter-panel-column {
    &__grid {
      background-color: #121212;
      color: white;
      border: 1px solid white;
    }
  }
}
