@import '../../../utils/mixins';

.login {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Rubik';

  @include onDesktop {
    display: flex;
    justify-content: center;
  }

  &__header {
    display: none;
    margin: 0;
    box-sizing: border-box;

    @include onDesktop {
      display: block;
      width: 100vw;
      height: 100vh;
    }
  }

  &__container {
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 35px 35px 50px 35px;
    background-color: #ffffff;
    z-index: 101;
    box-sizing: border-box;

    @include onDesktop {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 450px;
      padding: 30px;
      align-items: center;
      justify-content: space-between;
      border-radius: 45px;
    }
  }

  &__block-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;

    @include onDesktop {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  &__logo {
    width: 200px;
    height: 30px;

    @include onDesktop {
      display: none;
    }
  }

  &__logo-img {
    width: 100%;
  }

  &__button-close {
    background: transparent;
    border: none;
    background-image: url(../../../assets/images/Menu-clouse-registration.svg);
    background-size: cover;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 100;

  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    margin-bottom: 40px;

    @include onDesktop {      
      margin-bottom: 5px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    @include onDesktop {      
      padding: 30px;
    }
  }

  &__personal-information {
    display: flex;
    flex-direction: column;
    gap:45px;    
    width: 100%;
    margin-bottom: 52px;

    @include onDesktop {
      padding-top: 15px;
      margin-bottom: 35px;
      gap: 35px;
    }
  }

  &__item {
    padding-bottom: 5px;
    font-size: 14px;
    outline: none;
    border: none;
    font-weight: 400;
    border-bottom: 0.9px solid #b8b8b8;   
  }

  &__item::placeholder {
    font-weight: 400;
    color: #8b8a8a;
  }

  &__remember {
    display: flex;
    gap: 25px;
    flex-direction: row;
    margin-bottom: 55px;

    @include onDesktop {
      margin-bottom: 30px;
    }
  }

  &__remember-input {
    width: 20px;
    height: 20px;
    border: 2px solid #a6a6a6;
    border-radius: 6px;  
  }

  &__remember-label {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    color: #070a25;

    @include onDesktop {
      display: flex;

    }
  }

  &__remember-link {
    padding-left: 5px;
    font-weight: 700;
    color: #070a25;   
  }

  &__button {
    width: 100%;
    padding: 17px 40px;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    background: #0463ef;
    border: none;
    border-radius: 45px;
    margin-bottom: 45px;
    cursor: pointer;

    @include onDesktop {     
      
      margin-bottom: 0;
    }
  }

  &__button:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #4a8bee;
  }

  &__reset-password {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  &__reset-password-text {
    font-weight: 400;
    font-size: 14px;
    color: #070a25;
  }

  &__reset-password-link {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    text-decoration-line: underline;
    color: #0463ef;
  }
}
