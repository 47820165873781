#dots4 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
}

#dots4 span {
  position: absolute;
  top: 40%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: dots4 1.7s infinite ease-in-out both;
  animation: dots4 1.7s infinite ease-in-out both;
  margin-right: 5px;
}

#dots4.blue span {
  background: #0463ef;
}

#dots4 span:nth-child(1) {
  left: 0px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

#dots4 span:nth-child(2) {
  left: 15px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

#dots4 span:nth-child(3) {
  left: 30px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

#dots4 span:nth-child(4) {
  left: 45px;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

@keyframes dots4 {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes dots4 {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
