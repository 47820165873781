@import '../../../../utils/mixins';

.reset-password {
  font-family: 'Rubik';
  margin: 0;
  padding: 0;

  @include onDesktop {
    display: flex;
    justify-content: center;
  }

  &__header {
    display: none;
    margin: 0;
    box-sizing: border-box;
    @include onDesktop {
      display: block;
      width: 100vw;
      height: 100vh;
    }
  }

  &__container {
    // position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // height: 100vh;
    padding: 35px 35px 100px 35px;
    width: 100%;
    background-color: #ffffff;
    z-index: 101;
    box-sizing: border-box;

    @include onDesktop {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 450px;
      // height: 100%;
      padding: 30px;
      border-radius: 45px;
    }
  }

  &__block-menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;

    @include onDesktop {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  &__logo {
    width: 200px;
    height: 30px;

    @include onDesktop {
      display: none;
    }

    width: 100%;
  }

  &__button-close {
    background: transparent;
    border: none;
    background-image: url(../../../../assets/images/Menu-clouse-registration.svg);
    background-size: cover;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 100;

  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    margin-bottom: 60px;

    @include onDesktop {
    
      margin-bottom: 35px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include onDesktop {
      padding: 20px;
    }
  }

  &__personal-information {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 52px;

    @include onDesktop {
      margin-bottom: 35px;
      padding: 30px;
    }
  }

  &__item {
    width: 100%;
    outline: none;
    border: none;
    font-weight: 400;
    padding-bottom: 5px;
    font-size: 14px;
    border-bottom: 0.9px solid #b8b8b8;   
  }

  &__item::placeholder {
    font-weight: 400;
    color: #8b8a8a;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 17px 45px;
    font-weight: 700;
    font-size: 18px;
    line-height: 31px;
    color: #ffffff;
    background: #0463ef;
    border: none;
    border-radius: 45px;
    margin-bottom: 45px;
    margin-top: 25px;
    cursor: pointer;

    @include onDesktop {     
      margin-right: 20px;
      margin-left: 20px;
      margin-bottom: 25px;
    }
  }

  &__button:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: #4a8bee;
  }

  &__messege {
    color: red;
    font-weight: 400;
    font-size: 16px;   
    text-align: center; 
  }
}
